<template>
  <label>
    {{ label }}
    <br v-if="labelPosition != 'left'" />
    <select @change="$emit('change', $event.target.value)" v-model="status_local">
      <option v-for="g in SMOKE_VALUE" :key="g" :value="g">
        {{ SMOKE_CAPTION[g] }}
      </option>
    </select>
  </label>
</template>

<script>
import { SMOKE_VALUE, SMOKE_CAPTION } from "@/config/settings";
export default {
  name: "SmokeSelector",
  props: {
    label: String,
    labelPosition: String,
    status: String,
  },

  data: function () {
    return {
      status_local: "",
      SMOKE_VALUE,
      SMOKE_CAPTION,
    };
  },
  mounted() {
    if (this.status) this.status_local = this.status;
  },
};
</script>
