<template>
  <label>
    {{ label }}
    <br v-if="labelPosition != 'left'" />
    <select @change="$emit('change', $event.target.value)" ref="job" v-model="id_job_local">
      <option v-for="el in joblist" :value="el.id" :key="el.id">
        {{ el.job_name }}
      </option>
    </select>
  </label>
</template>

<script>
import { request } from "@/components-js/requestSrv";

export default {
  name: "JobSelector",
  props: {
    label: String,
    id_job: [String, Number],
    labelPosition: String,
  },

  data: function () {
    return { id_job_local: "", joblist: Array };
  },

  // watch: {
  //   id_job: function (newVal) {
  //     this.id_job_local = newVal;
  //   },
  // },

  async beforeMount() {
    this.joblist = await request("/api/job/list", "GET");
    if (this.id_job) this.id_job_local = this.id_job;
    else this.id_job_local = this.joblist[0].id;
    this.$emit("change", this.id_job_local);
  },
};
</script>
