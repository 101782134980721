<template>
  <div class="high" style="display: inline-grid">
    {{ label }}
    <select
      @change="$emit('change', pointListLocal)"
      ref="point"
      v-model="pointListLocal"
      multiple
    >
      <!-- <option v-if="allPointsFlag" value="%" :key="-1">Все точки</option> -->
      <option v-for="(el, i) in pointList" :value="el.id" :key="i">
        {{ el.nickname }}
      </option>
    </select>
  </div>
</template>

<script>
import { request } from "@/components-js/requestSrv";

export default {
  name: "PointSelectorMulti",
  props: { label: String, id_user: Number },

  data: function () {
    return { pointList: [], pointListLocal: [] };
  },

  async beforeMount() {
    this.pointList = await request("/api/point/list", "GET");
    this.pointListLocal = await request("/api/user/adminpointlist", "POST", {
      id: this.id_user,
      data: "id",
    });
    this.$emit("change", this.pointListLocal);
  },
};
</script>
