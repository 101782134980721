<template>
  <label>
    {{ label }}
    <br v-if="labelPosition != 'left'" />
    <select @change="$emit('change', $event.target.value)" v-model="valueLocal">
      <option v-for="g in BANK_VALUE" :key="g" :value="g">
        {{ BANK_CAPTION[g] }}
      </option>
    </select>
  </label>
</template>

<script>
import { BANK_VALUE, BANK_CAPTION } from "@/config/settings";
export default {
  name: "BankSelector",
  props: {
    label: String,
    labelPosition: String,
    value: String,
  },

  mounted() {
    this.valueLocal = this.value;
  },

  // watch: {
  //   value: function (newVal) {
  //     console.log(newVal);
  //     this.valueLocal = newVal;
  //   },
  // },

  data: function () {
    return {
      valueLocal: "",
      BANK_VALUE,
      BANK_CAPTION,
    };
  },
};
</script>
