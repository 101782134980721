const maskDefault = "+7(___)___-__-__";

function maskPhoneEngine(el, masked = maskDefault) {
  function mask(event) {
    const keyCode = event.keyCode;
    const template = masked;
    // def = template.replace(/\D/g, ""), // remove all non-digits
    // val = this.value.replace(/\D/g, ""); // remove all non-digits from new value

    let newValue = maskPhoneFormat(this.value, masked);
    /*
      let i = 0,
      newValue = template.replace(/[_\d]/g, function (a) {
        return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
      });

    i = newValue.indexOf("_");
    if (i !== -1) {
      newValue = newValue.slice(0, i);
    }
*/
    let reg = template
      .substr(0, this.value.length)
      .replace(/_+/g, function (a) {
        return "\\d{1," + a.length + "}";
      })
      .replace(/[+()]/g, "\\$&");
    reg = new RegExp("^" + reg + "$");
    if (!reg.test(this.value) || this.value.length < 5 || (keyCode > 47 && keyCode < 58)) {
      this.value = newValue;
    }
    if (event.type === "blur" && this.value.length < 5) {
      this.value = "";
    }
  }

  el.addEventListener("input", mask);
  el.addEventListener("focus", mask);
  el.addEventListener("blur", mask);
}

function maskPhoneFormat(val, masked = maskDefault) {
  const template = masked;
  const def = template.replace(/\D/g, "");
  val = val.replace(/\D/g, "");

  let i = 0,
    newValue = template.replace(/[_\d]/g, function (a) {
      return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
    });
  i = newValue.indexOf("_");
  if (i !== -1) {
    newValue = newValue.slice(0, i);
  }

  return newValue;
}

function maskPhoneLogin(val, masked = maskDefault) {
  const prefix = masked.replace(/\D/g, "");
  let res = val.replace(/\D/g, "");
  return res.slice(prefix.length);
}

function maskPhoneValidity(val, masked = maskDefault) {
  const valid = masked.replace(/_/g, "0");
  return val.replace(/\D/g, "").length == valid.replace(/\D/g, "").length;
}

export { maskPhoneEngine, maskPhoneFormat, maskPhoneLogin, maskPhoneValidity };
