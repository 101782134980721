<template>
  <div class="page-people-list">
    <h3 class="center">Люди</h3>
    <div class="page-people-list__filter heapR">
      <div class="margins1">
        <StatusSelector
          label="Критерий отбора"
          labelPosition="left"
          @change="status = $event"
        />
      </div>
      <div class="page-people-list__filter-checkbox margins1">
        <div class="flexR">
          <input type="checkbox" v-model="columnSetup" />
          &nbsp;Настр.столбц.
        </div>
      </div>

      <div v-show="columnSetup" class="center">
        <div v-for="(x, i) in columnList" :key="i" class="flexR margins1">
          <input type="checkbox" v-model="columnSelected" :value="x" />
          <div class="vertical-list02">&nbsp;{{ columnListCaption[i] }}</div>
        </div>
      </div>
    </div>

    <div v-if="loading">
      <Loader />
    </div>

    <div class="page-people-list__table mt-12" v-else>
      <div v-if="false" class="reminder smalltext">работает сортировка по столбцам</div>
      <table class="t_table">
        <tr class="noselect">
          <th style="width: 5%">N</th>
          <th
            v-for="(c, i) in columnList"
            :class="{ hide: columnSelected.indexOf(c) === -1, pointer: true }"
            :ref="c + '_sort'"
            :key="i"
            @click="sort(c, people, $refs, oFlag)"
          >
            {{ columnListCaption[i] }}
          </th>
        </tr>
        <!-- data rows -->
        <tr
          v-for="(row, y) in filter(people)"
          :key="y"
          @click="clickHandler($event, row.id)"
          class="user"
        >
          <td>{{ y + 1 }}</td>
          <td
            v-for="(c, i) in columnList"
            :class="{ hide: columnSelected.indexOf(c) === -1 }"
            :key="i"
            :ref="y === 0 ? 'cell' + c : null"
          >
            <span @click="enlarge(c, row[c])" v-html="decorateField(c, row[c])"></span>
          </td>
        </tr>
        <template v-if="filter(people).length === 0">
          <tr>
            <td colspan="300" class="center">нет данных по выбранным параметрам</td>
          </tr>
        </template>
        <tr class="user">
          <td colspan="300" style="border: none; background-color: white; padding-top: 1rem">
            <div @click="windowPeopleOpen(0)">добавить человека</div>
          </td>
        </tr>
      </table>
    </div>

    <PeopleWindow @hide="windowPeopleClose" :showFlag="showFlag" :personIn="person" />

    <div v-if="enlargeFlag" class="modalTurn" @click="close()">
      <div style="display: flex">
        <img :src="imagePhoto" alt="фоточка" style="object-fit: contain; height: 100vh" />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import StatusSelector from "@/components/StatusSelector.vue";
import { request } from "@/components-js/requestSrv";
import { decorateField } from "@/components-js/decorateField.js";
import PeopleWindow from "@/components/PeopleWindow.vue";
import { lS } from "@/components-js/localStorage";
import { sortCol } from "@/components-js/sortCol.js";
import { PHOTO_ROUTE } from "@/config/settings";

const loStorage = new lS();

export default {
  components: { Loader, StatusSelector, PeopleWindow },
  data() {
    return {
      loading: false,
      people: [],
      oFlag: 1,
      person: null,
      showFlag: false,
      // all columns to be shown
      columnList: [],
      // all columns captions
      columnListCaption: [],
      // columns selected to show
      columnSelected: [],
      // selection list show/hide
      columnSetup: false,
      // selected in filter
      status: String,
      enlargeFlag: 0,
      imagePhoto: "",
    };
  },

  watch: {
    columnSelected: function() {
      loStorage.saveObject(this.$route.path, this.columnSelected);
    },
  },

  async mounted() {
    this.tableDataRetrieve();
  },

  methods: {
    decorateField(field, value) {
      return decorateField(field, value);
    },

    clickHandler(ev, id) {
      if (ev.target.tagName !== "A" && ev.target.tagName !== "IMG") this.windowPeopleOpen(id);
    },

    async tableDataRetrieve() {
      this.loading = true;
      this.people = await request("/api/report/peoplelist");

      // this.columnList = this.people.slice(0, 1)[0];
      // set selectable columns
      const storedList = loStorage.getObject(this.$route.path);
      // console.log("storedList", storedList);
      if (storedList) Object.assign(this.columnSelected, storedList);
      else {
        this.columnSelected.push("surname");
        this.columnSelected.push("name");
        this.columnSelected.push("nickname");
        this.columnSelected.push("gender");
        this.columnSelected.push("job_name");
        this.columnSelected.push("date_hire");
      }

      this.columnList = [];
      this.columnList.push("surname");
      this.columnListCaption.push("Фамилия");
      this.columnList.push("name");
      this.columnListCaption.push("Имя");
      this.columnList.push("patronymic");
      this.columnListCaption.push("Отчество");
      this.columnList.push("nickname");
      this.columnListCaption.push("Ник");
      this.columnList.push("gender");
      this.columnListCaption.push("Пол");
      this.columnList.push("smoke_status");
      this.columnListCaption.push("Курение");
      this.columnList.push("phone");
      this.columnListCaption.push("Телефон");
      this.columnList.push("job_name");
      this.columnListCaption.push("Должность");
      this.columnList.push("auto_table");
      this.columnListCaption.push("'Автомат'");
      this.columnList.push("birth");
      this.columnListCaption.push("Дата рожд.");
      this.columnList.push("date_hire");
      this.columnListCaption.push("Дата приёма");
      this.columnList.push("login");
      this.columnListCaption.push("Логин");
      this.columnList.push("pass");
      this.columnListCaption.push("Пароль");
      this.columnList.push("telegram");
      this.columnListCaption.push("Telegram");
      this.columnList.push("photo");
      this.columnListCaption.push("Фото");
      this.columnList.push("job_extra");
      this.columnListCaption.push("Должность Доп.");

      this.loading = false;
    },

    // rolesOut(rolesArr) {
    //   let res = "";
    //   for (let i in rolesArr) {
    //     res += rolesArr[i];
    //     res += i < rolesArr.length - 1 ? ", " : "";
    //   }
    //   return res;
    // },

    filter(people) {
      return people.filter(i => {
        if (this.status === "workhouse" && (i.status === "work" || i.status === "pause"))
          return true;
        else return i.status === this.status;
      });
    },

    sort(col, arr, refs, oFlag) {
      this.oFlag = sortCol(col, arr, refs, oFlag);
    },

    enlarge(field, value) {
      if (field !== "photo") return;
      this.imagePhoto = `${PHOTO_ROUTE}${value}`;
      this.enlargeFlag = value;
    },

    close() {
      this.enlargeFlag = 0;
    },

    windowPeopleOpen(id) {
      // if person selected
      if (id) {
        const pos = this.people.findIndex(i => i.id === id);
        this.person = this.people[pos];
      } else {
        this.person = null;
      }
      // show window at least
      this.showFlag = true;
    },

    async windowPeopleClose(person) {
      this.showFlag = false;
      if (!person.id) return;
      await this.tableDataRetrieve();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-people-list {
  &__filter {
    flex-direction: row;
    align-items: center;
  }
  &__table {
    overflow-x: auto;
  }
  @media (max-width: 1300px) {
    &__filter {
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;
      font-weight: 400;

      input[type="checkbox"] {
        width: unset;
        height: unset;
      }
    }
    &__filter-checkbox {
      margin-top: 12px;
    }
  }
}
tr.user:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
